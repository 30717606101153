import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Content, { HTMLContent } from '../components/Content';

export const SuccessPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content
  if(typeof document !== "undefined") {
    document.body.className = 'page-success';
  }
  
  return (
    <section className="section">
      <div className="container-fluid">
        <div className="row">
          <div className="col col-md-10 offset-md-1 text-center">
            <div className="section">
              <h2>
                {title}
              </h2>
              <PageContent className="content" content={content} />
            </div>
            <div>
              <Link className="btn" to="/">Home</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

SuccessPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const SuccessPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
      <SuccessPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
  )
}

SuccessPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SuccessPage

export const successPageQuery = graphql`
  query SuccessPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
